import { push } from "connected-react-router"
import jwtDecode from "jwt-decode";
import { api } from '../helpers/util'
import config from "../config";

let token;
let decodedUser = {};

try{
  token = localStorage.getItem('token');
  decodedUser = jwtDecode(token);
} catch(err) {
  token = null;
}

export default {
  state: {
    _id: '',
    name: "",
    firstName: "",
    lastName: "",
    role: '',
    email: '',
    imageURL: '',
    authorized: !!token,
    hasCreatedProfile: false,
    token,
    loading: false,
    error: false,
    ownedBrandId: "",
    ownedBloggerId: "",
    ...decodedUser,
    notification: {
      type: '',
      message: '',
      description: '',
      active: false,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    login(state, payload) {
      return {...state, ...payload, error: false, authorized: true}
    },
    gotData(state, payload) {
      return { ...state, ...payload, loading: false, error: false, authorized: true, message: '' }
    },
    errorLogin(state, payload) {
      return {...state, ...payload, error: true}
    },
    showNotification(state, payload) {
      return { ...state, notification: { ...payload, active: true } }
    },
    hideNotification(state) {
      return { ...state, notification: { active: false } }
    },
  },
  effects: (dispatch) => ({
    async loginAction(payload, rootState) {
      const {email, password} = payload;

      try {
        const userData = {email, password};

        const {data} = await api({
          method: 'post',
          state: rootState,
          url: config.api.user.login,
          data: userData,
        });

        data.user.token = data.token;
        dispatch.user.gotData(data.user);
        localStorage.setItem('token', data.token);
        dispatch.user.login(data.user);

      } catch (error) {
        console.log("Error logging in: ", error);

        dispatch.user.showNotification({
          type: 'error',
          message: "Oops.. something isn't right!",
          description: "Make sure your login credentials are correct!",
        })
        dispatch.user.hideNotification({});

        dispatch.user.errorLogin(error.response.data);
      }
    },

    async updateUser (payload, rootState) {
      try {

        const {data} = await api({
          method: 'put',
          state: rootState,
          url: config.api.user.updateUser,
          data: payload,
        });

        data.user.token = data.token;
        dispatch.user.gotData(data.user);
        localStorage.setItem('token', data.token);
        dispatch.user.login(data.user);


        const landing = data.user.role === "brand" ? "/blogger/list" : "/brand/list";
        dispatch(push(landing));

      } catch (error) {
        dispatch.user.errorLogin(error.response.data);
      }
    },

    async createUser (payload, rootState) {
      try {

        const { data } = await api({
          method: 'post',
          state: rootState,
          url: config.api.user.createUser,
          data: payload,
        });

        data.user.token = data.token;
        dispatch.user.gotData(data.user);
        localStorage.setItem('token', data.token);
        dispatch.user.login(data.user);

      } catch (error) {
        dispatch.user.errorLogin(error.response.data);
      }
    },

    async forgotPassword (payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.user.forgotPassword,
          data: payload,
        });

      } catch (error) {
        dispatch.user.errorLogin(error.response.data);
      }
    },

    async resetPassword (payload, rootState) {
      try {
        await api({
          method: 'post',
          state: rootState,
          url: config.api.user.resetPassword,
          data: payload,
        });

      } catch (error) {
        dispatch.user.errorLogin(error.response.data);
      }
    },

    logoutAction() {
      localStorage.removeItem('token');
      window.location.reload();
    }
  })
}
