import React from 'react'
import { connect } from 'react-redux'
import { Icon, Badge } from 'antd'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

@connect(({ user, messages }) => ({ user, messages }), ({
  messages: { getUnreadMessagesCountAction },
}) => ({
  getUnreadMessagesCountAction
}))

class TopBar extends React.Component {

  componentDidMount() {
    const { getUnreadMessagesCountAction } = this.props;

    getUnreadMessagesCountAction();
  }

  render() {

    const { user, messages } = this.props;

    let brandsButton = (
      <div className="mr-4">
        <Icon type="bars" style={{ color: "#1ba6b5", paddingRight: 5, paddingTop: 30 }} />
        <a href="/brand/list">
          <strong><span>BRANDS</span></strong>
        </a>
      </div> 
    );

    let bloggersButton = (
      <div className="mr-4">
        <Icon type="bars" style={{ color: "#1ba6b5", paddingRight: 5, paddingTop: 30 }} />
        <a href="/blogger/list">
          <strong><span>BLOGGERS</span></strong>
        </a>
      </div> 
    );
    
    let messagesButton = (
      <div className="mr-4">
        <Icon type="wechat" style={{ color: "#1ba6b5", paddingRight: 5, paddingTop: 30 }} />
        
        <Badge count={messages.unreadMessagesCount}>
          <a href="/messages" style={{ paddingRight: 9 }}>
            <strong><span>MESSAGES</span></strong>
          </a>
        </Badge>
      </div>
    );
    
    let affiliatesButton = (
      <div className="mr-4">
        <Icon type="link" style={{ color: "#1ba6b5", paddingRight: 5, paddingTop: 30 }} />
        <a href="/affiliates">
          <strong><span>AFFILIATES</span></strong>
        </a>
      </div> 
    );;

    // Admin
    if (user.role === "admin") {
      messagesButton = null;
      affiliatesButton = null;
    
    // Brand
    } else if (user.role === "brand") {
      brandsButton = null;
    
    // Blogger
    } else {
      bloggersButton = null;
    }

    return (
      <div className={styles.topbar}>
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <a href="/">
              <img src="/resources/images/purpicks/logo/logo.png" className={styles.logoCenter} alt="logo" />
            </a>
          </div>
        </div>

        <div className="mr-auto" />

        {brandsButton}

        {bloggersButton}

        {affiliatesButton}

        {messagesButton}

        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
