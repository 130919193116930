const apiUrl = 'https://purp-api.buildable.io' // PRODUCTION
// const apiUrl = "http://localhost:4000"; // DEVELOPMENT
const version = 'v1';

export default {
  buildable: {
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiUHVyUGlja3MiLCJ3ZWJzaXRlIjoicHVycGlja3MuY29tIiwicGFja2FnZSI6eyJuYW1lIjoiU3RhcnR1cCIsImNvbm5lY3Rpb25zIjo1MDAwMDAsIm1vZHVsZXMiOjUsImVuZHBvaW50cyI6MzN9LCJfaWQiOiI1YzZiMzI2YmRjOWQxYTFlMDlmMGZkMzQiLCJpYXQiOjE1NTkzMjgxMzgsImV4cCI6MTYyMjQ0MzMzOCwiYXVkIjoiVmpXYWl4dFVBVkwxZllvQkZ0R2kiLCJpc3MiOiJidWlsZGFibGUifQ.R7q-uJ2dFwulW3G6m_jxcoVhRW7-GKhIw39vcVwUDX4"
  },

  api: {
    user: {
      login: `${apiUrl}/${version}/login`,
      updateUser: `${apiUrl}/${version}/user`,
      createUser: `${apiUrl}/${version}/users/add`,
      forgotPassword: `${apiUrl}/${version}/forgot/password`,
      resetPassword: `${apiUrl}/${version}/reset/password`,
    },

    brands: {
      setBrandActivation: `${apiUrl}/${version}/brands/set-activation`,
      pushUpdate: `${apiUrl}/${version}/brands/update`,
      deleteSingleBrand: `${apiUrl}/${version}/brands/delete`,
      getManyBrands: `${apiUrl}/${version}/brands/get-many`,
      createBrand: `${apiUrl}/${version}/brands/create`
    },

    bloggers: {
      setBloggerActivation: `${apiUrl}/${version}/bloggers/set-activation`,
      pushUpdate: `${apiUrl}/${version}/bloggers/update`,
      deleteSingleBlogger: `${apiUrl}/${version}/bloggers/delete`,
      getManyBloggers: `${apiUrl}/${version}/bloggers/get-many`,
      createBlogger: `${apiUrl}/${version}/bloggers/create`
    },

    affiliates: {
      setAffiliateActivation: `${apiUrl}/${version}/affiliates/set-activation`,
      pushUpdate: `${apiUrl}/${version}/affiliates/update`,
      deleteSingleAffiliate: `${apiUrl}/${version}/affiliates/delete`,
      updateAffiliatesTracking: `${apiUrl}/${version}/affiliates/update-tracking`,
      getManyAffiliates: `${apiUrl}/${version}/affiliates/get-many`,
      createAffiliate: `${apiUrl}/${version}/affiliates/create`
    },

    messages: {
      setMessageActivation: `${apiUrl}/${version}/communications/set-activation`,
      pushUpdate: `${apiUrl}/${version}/communications/update`,
      deleteSingleMessage: `${apiUrl}/${version}/communications/delete`,
      getManyMessages: `${apiUrl}/${version}/communications/get-many`,
      createMessage: `${apiUrl}/${version}/communications/create`
    }
  }
}
