import config from '../config'
import { api } from '../helpers/util'

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData (state) {
      return { ...state, loading: true, error: false }
    },
    selectBlogger (state, payload) {
      payload.updates = payload.updates ? payload.updates.reverse() : []
      return { ...state, selected: payload }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    gotError (state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: (dispatch) => ({

    async setBloggerActivationAction (payload, rootState) {
      try {
        dispatch.bloggers.gettingData()
        
        const {data: blogger} = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.setBloggerActivation,
          data: payload,
        });

        dispatch.bloggers.selectBlogger(blogger)
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },

    async pushBloggerUpdateAction (payload, rootState) {
      try {
        
        const query = {
          _id: payload._id
        };

        delete payload._id;

        const { data: blogger } = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.pushUpdate,
          data: {
            payload, 
            query
          },
        });

        dispatch.bloggers.selectBlogger(blogger)
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },

    async pushBloggerUpdateAndGetAction (payload, rootState) {
      try {
        
        const query = {
          _id: payload._id
        };

        delete payload._id;

        const { data: blogger } = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.pushUpdate,
          data: {
            payload, 
            query
          },
        });

        dispatch.bloggers.selectBlogger(blogger);
        dispatch.bloggers.getManyBloggersAction(query);
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },

    async deleteBloggerAction (payload, rootState) {
      try {
        dispatch.bloggers.gettingData()

        await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.deleteSingleBlogger,
          data: payload,
        });
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },
    
    async getManyBloggersAction (payload, rootState) {
      try {
        dispatch.bloggers.gettingData()

        const { data: bloggers } = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.getManyBloggers,
          data: payload,
        });

        dispatch.bloggers.gotData(bloggers);
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },

    async createBloggerAction (payload, rootState) {
      try {
        dispatch.bloggers.gettingData()

        const { data: blogger } = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.createBlogger,
          data: payload,
        });

        dispatch.bloggers.selectBlogger(blogger);
      }
      catch (error) {
        dispatch.bloggers.gotError(error);
      }
    },

    async createBloggerAndUpdateUserAction (payload, rootState) {
      try {
        dispatch.bloggers.gettingData()
  
        const { data: blogger } = await api({
          method: 'post',
          state: rootState,
          url: config.api.bloggers.createBlogger,
          data: payload,
        });
  
        dispatch.user.updateUser({
          email: rootState.user.email,
          ownedBloggerId: blogger.insertedIds[0],
          hasCreatedProfile: true
        });
      }
      catch (error) {
        dispatch.bloggers.gotError(error.message)
      }
    }
  })
}
