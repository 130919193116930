import config from '../config'
import { api } from '../helpers/util'

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData (state) {
      return { ...state, loading: true, error: false }
    },
    selectAffiliate (state, payload) {
      return { ...state, selected: payload }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    gotError (state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: (dispatch) => ({

    async setAffiliateActivationAction (payload, rootState) {
      try {
        dispatch.affiliates.gettingData()
        
        const {data: affiliate} = await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliate.setAffiliateActivation,
          data: payload,
        });

        dispatch.affiliates.selectAffiliate(affiliate)
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    async pushAffiliateUpdateAction (payload, rootState) {
      try {
        const { data: affiliate } = await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.pushUpdate,
          data: payload,
        });
        dispatch.affiliates.selectAffiliate(affiliate)
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    async deleteAffiliateAction (payload, rootState) {
      try {
        dispatch.affiliates.gettingData();

        await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.deleteSingleAffiliate,
          data: payload,
        });
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    async updateAffiliatesTrackingAction (payload = {}, rootState) {
      try {
        dispatch.affiliates.gettingData()
         
        await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.updateAffiliatesTracking,
          data: payload,
        });
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },
    
    async getManyAffiliatesAction (payload = {}, rootState) {
      try {
        dispatch.affiliates.gettingData()

        const brandId = rootState.user.ownedBrandId;

        payload.brandId = brandId;

        // Update tracking
        await dispatch.affiliates.updateAffiliatesTrackingAction({
          brandId
        });
         
        const { data: affiliates } = await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.getManyAffiliates,
          data: payload,
        });

        dispatch.affiliates.gotData(affiliates);
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    async getManyBloggerAffiliatesAction (payload = {}, rootState) {
      try {
        dispatch.affiliates.gettingData()

        const bloggerId = rootState.user.ownedBloggerId;

        payload.bloggerId = bloggerId;
         
        const { data: affiliates } = await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.getManyAffiliates,
          data: payload,
        });

        const brandIds = [];
        affiliates.forEach(affiliate => {
          if (!brandIds.includes(affiliate.brandId)) {
            brandIds.push(affiliate.brandId);
          }
        });

        // eslint-disable-next-line no-restricted-syntax
        for await (const brandId of brandIds) {
          await dispatch.affiliates.updateAffiliatesTrackingAction({
            brandId
          });
        }

        dispatch.affiliates.gotData(affiliates);
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    async createAffiliateAction (payload, rootState) {
      try {
        dispatch.affiliates.gettingData()

        payload.brandId = rootState.user.ownedBrandId;

        const { data: affiliate } = await api({
          method: 'post',
          state: rootState,
          url: config.api.affiliates.createAffiliate,
          data: payload,
        });

        dispatch.affiliates.getManyAffiliatesAction();
        dispatch.affiliates.selectAffiliate(affiliate);
      }
      catch (error) {
        dispatch.affiliates.gotError(error.response)
      }
    },

    selectAffiliateAction(payload, rootState) {
      const selectedAffiliate = rootState.affiliates.data.find(affiliate => affiliate._id === payload)
      dispatch.affiliates.selectAffiliate(selectedAffiliate);
    }
  }),
}
