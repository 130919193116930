import Moment from "moment";
import config from '../config'
import { api } from '../helpers/util'

export default {
  state: {
    loading: false,
    data: [{
      _id: 1,
      author: {
        role: "brand",
      },
      blogger: {
        firstName: "PurPicks",
        lastName: "Admin"
      },
      createdAt: new Date(),
      messages:[{
        _id: 1,
        message: "No messages in your inbox!",
        createdAt: new Date(),
        sender: {
          firstName: "PurPicks",
          lastName: "Admin"
        }
      }]
    }],
    selected: {
      _id: 1,
      author: {
        role: "brand",
      },
      blogger: {
        firstName: "PurPicks",
        lastName: "Admin"
      },
      createdAt: new Date(),
      messages:[{
        _id: 1,
        message: "No messages in your inbox!",
        createdAt: new Date(),
        sender: {
          firstName: "PurPicks",
          lastName: "Admin"
        }
      }]
    },
    unreadMessagesCount: 0
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData (state) {
      return { ...state, loading: true, error: false }
    },
    selectMessage (state, payload) {
      payload.updates = payload.updates ? payload.updates.reverse() : []
      return { ...state, selected: payload }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    gotUnreadCount(state, payload) {
      return { ...state, unreadMessagesCount: payload }
    },
    gotError (state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: (dispatch) => ({

    async getManyMessagesAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const request = rootState.user.role === "brand" ? { 
          brandId: rootState.user.ownedBrandId 
        } : {
          bloggerId: rootState.user.ownedBloggerId
        };
         
        const { data: messages } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.getManyMessages,
          data: request,
        });

        if (messages.length !== 0) {
          dispatch.messages.gotData(messages.reverse());
        }
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    },

    async getManyMessagesAndSelectAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const request = rootState.user.role === "brand" ? { 
          brandId: rootState.user.ownedBrandId 
        } : {
          bloggerId: rootState.user.ownedBloggerId
        };
        
        const { data: messages } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.getManyMessages,
          data: request,
        });

        if (messages.length !== 0) {

          messages.sort((a, b)=>{
            const keyA = Moment.utc(a.messages[a.messages.length-1].createdAt);
            const keyB = Moment.utc(b.messages[b.messages.length-1].createdAt);
            
            // Compare the 2 dates
            if(keyA < keyB) return -1;
            if(keyA > keyB) return 1;
            return 0;
          }).reverse();

          dispatch.messages.gotData(messages);
          dispatch.messages.selectMessage(messages[0]);
          dispatch.messages.markAsReadAction({ _id: messages[0]._id});
        }
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    },

    async getUnreadMessagesCountAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const request = rootState.user.role === "brand" ? { 
          brandId: rootState.user.ownedBrandId 
        } : {
          bloggerId: rootState.user.ownedBloggerId
        };
         
        const { data: messages } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.getManyMessages,
          data: request,
        });

        if (messages.length !== 0) {
          let unreadCount = 0;
          messages.forEach((curMessage) => {
            curMessage.messages.forEach((message) => {

              // Handle older messages
              if (typeof message.seenByBrand !== "undefined" && typeof message.seenByBlogger !== "undefined") {
                if (rootState.user.role === "brand" && !message.seenByBrand) {
                  unreadCount += 1;
                } else if (rootState.user.role === "blogger" && !message.seenByBlogger) {
                  unreadCount += 1;
                }
              }
            });
          });

          dispatch.messages.gotUnreadCount(unreadCount);
        }
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    },

    async markAsReadAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const query = {
          _id: payload._id
        };

        const { data: messages } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.getManyMessages,
          data: query
        });
        
        messages[0].messages.forEach((message) => {
          if (rootState.user.role === "brand") {
            message.seenByBrand = true;
          } else if (rootState.user.role === "blogger") {
            message.seenByBlogger = true;
          }
        });

        delete payload._id;
        payload.messages = messages[0].messages;

        await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.pushUpdate,
          data: {
            payload, 
            query
          },
        });
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    },

    async markAsReadAndSelectAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const query = {
          _id: payload._id
        };

        const { data: messages } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.getManyMessages,
          data: query
        });
        
        messages[0].messages.forEach((message) => {
          if (rootState.user.role === "brand") {
            message.seenByBrand = true;
          } else if (rootState.user.role === "blogger") {
            message.seenByBlogger = true;
          }
        });

        delete payload._id;
        payload.messages = messages[0].messages;

        await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.pushUpdate,
          data: {
            payload, 
            query
          },
        });

        // dispatch.messages.getManyMessagesAndSelectAction();
        dispatch.messages.getUnreadMessagesCountAction();
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    },

    async createMessageAction (payload, rootState) {
      try {
        dispatch.messages.gettingData();

        const { data: message } = await api({
          method: 'post',
          state: rootState,
          url: config.api.messages.createMessage,
          data: payload,
        });

        dispatch.messages.selectMessage(message);

        const request = rootState.user.role === "brand" ? { 
          brandId: rootState.user.ownedBrandId 
        } : {
          bloggerId: rootState.user.ownedBloggerId
        };

        dispatch.messages.getManyMessagesAction(request);
      }
      catch (error) {
        dispatch.messages.gotError(error);
      }
    }
  }),
}
