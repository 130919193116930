import config from '../config'
import { api } from '../helpers/util'

export default {
  state: {
    loading: false,
    data: [],
    selected: {},
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    gettingData (state) {
      return { ...state, loading: true, error: false }
    },
    selectBrand (state, payload) {
      payload.updates = payload.updates ? payload.updates.reverse() : []
      return { ...state, selected: payload }
    },
    gotData(state, payload) {
      return { ...state, data: payload, loading: false, error: false, message: '' }
    },
    gotError (state, payload) {
      return { ...state, ...payload, error: true }
    },
  },
  effects: (dispatch) => ({

    async setBrandActivationAction (payload, rootState) {
      try {
        dispatch.brands.gettingData()
        
        const {data: brand} = await api({
          method: 'post',
          state: rootState,
          url: config.api.brand.setBrandActivation,
          data: payload,
        });

        dispatch.brands.selectBrand(brand)
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },

    async pushBrandUpdateAction (payload, rootState) {
      try {

        const query = {
          _id: payload._id
        };

        delete payload._id;

        const { data: brand } = await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.pushUpdate,
          data: {
            payload, 
            query
          },
        });

        dispatch.brands.selectBrand(brand)
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },

    async pushBrandUpdateAndGetAction (payload, rootState) {
      try {

        const query = {
          _id: payload._id
        };

        delete payload._id;

        const { data: brand } = await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.pushUpdate,
          data: {
            payload, 
            query
          },
        });

        dispatch.brands.selectBrand(brand);
        dispatch.brands.getManyBrandsAction(query);
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },

    async deleteBrandAction (payload, rootState) {
      try {
        dispatch.brands.gettingData()

        await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.deleteSingleBrand,
          data: payload,
        });
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },
    
    async getManyBrandsAction (payload, rootState) {
      try {
        dispatch.brands.gettingData()
        
        const { data: brands } = await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.getManyBrands,
          data: payload,
        });

        dispatch.brands.gotData(brands)
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },

    async createBrandAction (payload, rootState) {
      try {
        dispatch.brands.gettingData()

        const { data: brand } = await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.createBrand,
          data: payload,
        });

        dispatch.brands.selectBrand(brand);
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    },
    
    async createBrandAndUpdateUserAction (payload, rootState) {
      try {
        dispatch.brands.gettingData()
  
        const { data: brand } = await api({
          method: 'post',
          state: rootState,
          url: config.api.brands.createBrand,
          data: payload,
        });
  
        dispatch.user.updateUser({
          email: rootState.user.email,
          ownedBrandId: brand.insertedIds[0],
          hasCreatedProfile: true
        });
      }
      catch (error) {
        dispatch.brands.gotError(error.message)
      }
    }
  })
}
