import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/register',
    component: loadable(() => import('pages/user/register')),
    exact: true,
  },
  {
    path: '/reset-password',
    component: loadable(() => import('pages/user/reset-password')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/brand/list',
    component: loadable(() => import('pages/brand/list')),
    exact: true,
  },
  {
    path: '/brand/:brandId',
    component: loadable(() => import('pages/brand/view')),
    exact: true,
  },
  {
    path: '/brand/edit/:brandId',
    component: loadable(() => import('pages/brand/edit')),
    exact: true,
  },
  {
    path: '/blogger/list',
    component: loadable(() => import('pages/blogger/list')),
    exact: true,
  },
  {
    path: '/blogger/:bloggerId',
    component: loadable(() => import('pages/blogger/view')),
    exact: true,
  },
  {
    path: '/blogger/edit/:bloggerId',
    component: loadable(() => import('pages/blogger/edit')),
    exact: true,
  },
  {
    path: '/create-blogger-profile',
    component: loadable(() => import('pages/user/createBloggerProfile')),
    exact: true,
  },
  {
    path: '/create-brand-profile',
    component: loadable(() => import('pages/user/createBrandProfile')),
    exact: true,
  },
  {
    path: '/edit-profile',
    component: loadable(() => import('pages/edit-profile')),
    exact: true,
  },
  {
    path: '/messages',
    component: loadable(() => import('pages/message')),
    exact: true,
  },
  {
    path: '/affiliates',
    component: loadable(() => import('pages/affiliates')),
    exact: true,
  }
]

@connect(({ user }) => ({ user }))
class Router extends React.Component {
  render() {
    const { history } = this.props
   
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
