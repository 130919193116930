import axios from 'axios/index'
import config from '../config'

export const api = async ({state, data, method, url}) => {
  try {

    return await axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${state.user.token}`,
        "Buildable-Authorization": `Bearer ${config.buildable.token}`
      },
      data
    });

  } catch(e) {
    throw e;
  }
};

