export default {
  state: {
    menuLeftData: [
      { key: 1, title: 'Hot Leads', url: '/', icon: 'lnr lnr-users', disabled: false },
      { key: 2, title: 'Lists', url: '/', icon: 'lnr lnr-list', disabled: false },
      { key: 3, title: 'Templates', url: '/', icon: 'lnr lnr-envelope', disabled: false },
      { key: 4, title: 'Queues', url: '/', icon: 'lnr lnr-rocket', disabled: false },
      {
        key: 5,
        title: 'Automation',
        url: '/automation',
        icon: 'lnr lnr-cloud-sync',
        disabled: false,
      },
    ],
    menuTopData: [],
    selectedKeys: [5],
    openedKeys: [5],
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload
    },
  },
  effects: dispatch => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async incrementAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      console.log(rootState)
      dispatch.count.increment(payload)
    },
  }),
}
