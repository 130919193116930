export default {
  state: {
    pageIsOffCanvas: false,
    pageCanvasDirection: null,
    isModalOpen: false,
    openModalType: null,
    openModalData: null,
    modalMessages: null,
    toggledPanelStates: {},
    panelOptionsCollapsed: [
      'tags',
      'compatible-with',
      'software-version'
    ],
    selectedItem: null,
    offsetTop: null,
    isSidebarOpen: false
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async incrementAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log(rootState);
      dispatch.count.increment(payload)
    }
  })
}
