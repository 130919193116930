import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Tag } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }), ({
  user: { logoutAction },
}) => ({
  logoutAction
}))
class ProfileMenu extends React.Component {

  logout = () => {
    const { logoutAction } = this.props
    logoutAction();
  }

  render() {
    const { user } = this.props

    let showEditProfileTypeButton;

    if (user.hasCreatedProfile) {
      if (user.role === "brand") {
        showEditProfileTypeButton = (
          <Menu.Item>
            <a href={`/brand/edit/${user.ownedBrandId}`}>
              <i className={`${styles.menuIcon} icmn-user`} />
              <FormattedMessage id="topBar.profileMenu.editBrandProfile" />
            </a>
          </Menu.Item>
        );
      } else if (user.role === "blogger") {
        showEditProfileTypeButton = (
          <Menu.Item>
            <a href={`/blogger/edit/${user.ownedBloggerId}`}>
              <i className={`${styles.menuIcon} icmn-user`} />
              <FormattedMessage id="topBar.profileMenu.editBloggerProfile" />
            </a>
          </Menu.Item>
        );
      }
    }

    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />{', '}
          </strong>
          {user.firstName ? `${user.firstName} ${user.lastName}` : 'Anonymous'}
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            <Tag color="#1BA6B6" style={{ marginLeft: 3, fontSize: 11 }}>{user.role.toUpperCase() || ''}</Tag>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
            <br />
          </div>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item>
          <a href="/edit-profile">
            <i className={`${styles.menuIcon} icmn-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        </Menu.Item>

        {showEditProfileTypeButton}
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" style={{ background: "#1ba6b6"}} />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
